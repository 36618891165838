@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800&family=Roboto:ital,wght@0,100;0,300;0,400;1,100;1,300;1,500&display=swap');

body {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #c9d1d3;
}

#root {
  border: 2px solid black;
  min-width: 840px;
  min-height: 680px;
  margin-top: 5px;
}
